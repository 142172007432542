<template>
  <div class="search">
   
    <div class="Latestfeatures-list">
      <div class="zuixin-gingne">最新活动</div>
      <div class="zuixin-gongneng">
        <div class="ziti">{{detailist.title}}</div>
        <div class="time">{{detailist.createtime}}</div>
        <div class="wenben-top" v-html="detailist.details.replace(/\n/g,'<br/>')"  @click="htmlBoxClick($event)"></div>
        <el-image
                style="display: none;"
                ref="preview"
                class="hideImgDiv"
                :src="imgPreviewSrc[0]"
                :preview-src-list="imgPreviewSrc"
            ></el-image>
      </div>
    </div>
  </div>
</template>


<script>
  export default {
    name:"Latestfeatures",
    data () {
      return {
        show:false,
        id:'',
        detailist:{},
        imgPreviewSrc:""
      }
    },
    mounted () {
     this.id = this.$route.query.id
     this.getsousuolist();
     window.scrollTo(0, 0);
    },
    methods: {
       // 图片点击放大
      htmlBoxClick(e){ 
        // console.log(e,'gggggg')
        if (e.target.nodeName === 'IMG'|| e.target.nodeName == 'img') { //判断是否图片标签
          const img = e.target.currentSrc //获取点击图片地址
          this.imgPreviewSrc=[img];
          this.$refs.preview.clickHandler();
          return false;
        }
      },
      //详情列表
      getsousuolist(){
        let that = this
        that.$http({
          url: that.baseurl +"/getActivityCenterInfo?id="+ that.id,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  // that.$message.success(res.data.data);
                  that.detailist = res.data.data
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
       handleNodeClick(data) {
        console.log(data);
      }
    },
  }

</script>
<style scoped>
  .search{
    width: 100%;
    background-color: #F2F5FB ;
    padding-top:87px;
  }
  .zuixin-gingne{
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    padding: 0 0 22px 0;
  }
  .Latestfeatures-list{
    width: 100%;
    max-width: 1520px;
    /* height: 500px; */
    margin: 0px auto;
    padding-bottom: 20px;
  }
  
  .zuixin-gongneng{
    width: 100%;
    min-height: 1215px;
    background-color: #ffffff;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.03);
    border-radius: 8px; 
  
    padding: 20px 0;
  }
  .wenben-top{
    width: 90%;
    margin: 0 auto;
    padding: 15px 0;
    white-space: pre-wrap;
  }
  .wenben-top>>> img {
        max-width: 100% ;
  }
  .ziti{
    font-weight: 550;
    font-size: 32px;
    color: #333333;
      text-align: center;
  }
.time{
font-weight: 400;
font-size: 14px;
color: #999999;
padding: 15px 0;
  text-align: center;
}
</style>

